import { makeObservable, observable } from 'mobx';
import { EtlJob, EtlJobData, JobStatus, Pod, PodStatus } from 'shared';

import { AnalyticsStore } from '@stores/analytics.store';

import Model, { ModelError } from './base/base.model';

export class AnalyticsModel extends Model {
  public name: string;
  data: EtlJobData;
  cron_expression?: string;
  description?: string | null;
  private created_by?: string;
  starts_at?: string;
  ends_at?: string | null;
  private status: JobStatus;
  sentry_monitor?: string;
  sentry_dsn?: string;
  pods: Pod[];
  image?: string;
  constructor(store: AnalyticsStore, analytics: EtlJob, loading?: boolean) {
    super(store, analytics.id, loading);
    this.name = analytics.name;
    this.data = analytics.data;
    this.cron_expression = analytics.cron_expression;
    this.description = analytics.description;
    this.created_by = analytics.created_by;
    this.starts_at = analytics.starts_at;
    this.ends_at = analytics.ends_at;
    this.status = analytics.status;
    this.sentry_monitor = analytics.sentry_monitor;
    this.sentry_dsn = analytics.sentry_dsn;
    this.pods = analytics.pods || [];
    this.image = analytics.image;
    makeObservable<
      AnalyticsModel,
      | 'name'
      | 'cron_expression'
      | 'description'
      | 'data'
      | 'created_by'
      | 'starts_at'
      | 'ends_at'
      | 'status'
      | 'sentry_monitor'
      | 'sentry_dsn'
      | 'image'
      | 'pods'
    >(this, {
      name: observable,
      cron_expression: observable,
      description: observable,
      data: observable,
      created_by: observable,
      starts_at: observable,
      ends_at: observable,
      status: observable,
      sentry_monitor: observable,
      sentry_dsn: observable,
      image: observable,
      pods: observable
    });
  }
  /* ------------------------ Class properties getters ------------------------ */

  getCreatedBy() {
    return this.created_by;
  }

  getName() {
    return this.name;
  }
  getCronExpression() {
    return this.cron_expression;
  }
  getStatus() {
    return this.status;
  }
  getStartsAt() {
    return this.starts_at;
  }
  getEndsAt() {
    return this.ends_at;
  }
  getData() {
    return this.data;
  }
  getDescription() {
    return this.description;
  }
  getSentryMonitor() {
    return this.sentry_monitor;
  }
  getSentryDsn() {
    return this.sentry_dsn;
  }

  getImage() {
    return this.image;
  }

  getPods() {
    return this.pods;
  }
  /* ------------------------ Class properties setters ------------------------ */

  setStatus(newStatus: JobStatus) {
    this.status = newStatus;
  }

  /* ---------------------------- Computed getters ---------------------------- */
  get targetedPod(): Pod | undefined {
    if (this.pods === null || this.pods?.length === 0) {
      return;
    }

    const firstSuccessfullPod = this.pods?.find(
      (pod) => pod.status === PodStatus.SUCCESS
    );

    if (firstSuccessfullPod !== undefined) {
      return firstSuccessfullPod;
    }

    const tempPods = Array.from(this.pods);

    tempPods.sort((a, b) => {
      const aDate = new Date(a.triggered_at);
      const bDate = new Date(b.triggered_at);
      return aDate.getTime() - bDate.getTime();
    });

    const lastlyCreatedPod = tempPods[this.pods.length - 1];

    return lastlyCreatedPod;
  }

  get shouldBePolled(): boolean {
    return (
      this.status === JobStatus.RECEIVED || this.status === JobStatus.SCHEDULED
    );
  }

  /* ------------------------- Abstract class methods ------------------------- */
  get toJSON() {
    return {};
  }

  get isDeletable(): boolean {
    return false;
  }

  get errors(): ModelError[] {
    return [];
  }
}
