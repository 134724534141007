import { z } from 'zod';

import {
  createJobSchema,
  jobSchema,
  updateJobSchema
} from '../generation/job.schema';

export const etlDataSchema = z.object({
  rs_database: z.string(),
  schema: z.string(),
  wf_ids: z.array(z.number()),
  filters: z.array(z.string()).optional(),
  column_filter: z.array(z.string()).optional()
});

export const analyticsMetadataSchema = z.object({
  numberOfPods: z.number()
});
export const pauseJobSchema = z.object({
  pause: z.boolean()
});

export const etlJobSchema = jobSchema(etlDataSchema).merge(
  analyticsMetadataSchema
);

export const createEtlJobSchema = createJobSchema(etlDataSchema);
export const updateEtlJobSchema = updateJobSchema(etlDataSchema).partial();

/* ---------------------------------- DTO  ---------------------------------- */
export type EtlJobData = z.infer<typeof etlDataSchema>;
export type EtlJob = z.infer<typeof etlJobSchema>;
export type AnalyticsMetadata = z.infer<typeof analyticsMetadataSchema>;
export type CreateEtlJobDTO = z.infer<typeof createEtlJobSchema>;
export type UpdateEtlJobDTO = z.infer<typeof updateEtlJobSchema>;
export type PauseJobDTO = z.infer<typeof pauseJobSchema>;
