import { useState } from 'react';

import { observer } from 'mobx-react';
import { AtomReference, TOBlocks } from 'shared';
import { toast } from 'sonner';

import DNDBase from '@components/dnd/base';
import StateMenu from '@components/stateMenu';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';
import SettingHeader from '@pages/Settings/SettingHeader';
import { SettingsContent } from '@pages/Settings/settings.style';
import { SettingBody } from '@pages/Settings/style';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import ActionButton from '@atoms/button';

import { newError } from '@/services/errors/errors';
import { isTOBlocks } from '@library/traceOverview';
import { CircularProgress, Modal, ModalClose } from '@mui/joy';

const TraceOverview = () => {
  const { atomStore } = useStores();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const process = useProcess();

  const traceOverviewDnd = process?.workflows[0]?.traceOverviewDnd;

  if (!process) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  const onSelected = (selectedVariableRef: Maybe<AtomReference>) => {
    if (!selectedVariableRef) return;
    if (!traceOverviewDnd) return;
    const selectedVariable = atomStore.getAtomById(
      selectedVariableRef.dataItemId,
      selectedVariableRef.blockType as TOBlocks
    );

    if (!selectedVariable || selectedVariable instanceof Error) {
      throw newError('TO-1234', 'Variable not found');
    }

    if (!selectedVariable.variableInfo) {
      throw newError('TO-4321', 'Selected variable has no variable infos');
    }

    const TOBlockType = `TO_${selectedVariable.variableInfo.resolvedType}`;

    if (!isTOBlocks(TOBlockType)) {
      toast.info('Cannot select this type of variable yet.');
      throw newError(
        'TO-qda12',
        'Selected variable resolved type is not a TI block'
      );
    }

    const newTOBlock = traceOverviewDnd.createDndBlock(TOBlockType, '');

    newTOBlock.other.selectedVariableRef = selectedVariableRef;

    const path = 'root:root|0';

    // The new TO block is added to the window
    const stateWithNewBlock = traceOverviewDnd.insertBlockInState(
      path,
      traceOverviewDnd.state,
      newTOBlock
    );

    traceOverviewDnd.setState(stateWithNewBlock);

    // wait async 0.5s to make sure the new block is added to the state
    setTimeout(() => {
      const newTOBlockAtom = atomStore.get(newTOBlock.atomId);

      if (!newTOBlockAtom) {
        throw newError('TO-1234', 'New TO block atom not found');
      }

      newTOBlockAtom.addReferenceToAtom(selectedVariable.id);
    }, 500);
  };

  return (
    <SettingsContent>
      <SettingHeader
        title="Trace overview"
        description="Customize the big summary table where all Traces are referenced"
      />

      {/* <SettingHelper
        title="Trace overview"
        icon={<Grid3X3 />}
        description="The Trace overview is the table you see when you open your workflow in Trace. It shows all the Traces in your workflow and gives you an overview of their state. Only use the most relevant variables here. You can change what columns should be displayed by adding widgets."
      /> */}

      {process.loading && <></>}
      {!process.loading && traceOverviewDnd && (
        <SettingBody>
          <div className="title-body">
            <h3>Trace overview settings</h3>
            <ActionButton
              value="Add a column"
              onClick={() => setOpenModal(true)}
            />
          </div>
          <DNDBase dnd={traceOverviewDnd} />
        </SettingBody>
      )}

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '70px'
        }}
      >
        <>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <StateMenu
            onSelected={onSelected}
            onClose={() => setOpenModal(false)}
          />
        </>
      </Modal>
    </SettingsContent>
  );
};

export default observer(TraceOverview);
