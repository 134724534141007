import { GitCommitHorizontal } from 'lucide-react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { Avatar, AvatarImage } from '@components/ui/avatar';
import { Badge } from '@components/ui/badge';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';
import { generationStatusToProgress } from '@pages/Settings/generation';
import { GenerationContainer } from '@pages/Settings/generation/generation.style';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { GenerationModel } from '@models/generation.model';

import { ENV, ENV_TO_READABLE_NAME } from '@/utils/constants';
import { CircularProgress } from '@mui/joy';

const LastGenerations = () => {
  const process = useProcess();
  const { processStore } = useStores();
  const navigate = useNavigate();
  const MAX_NAME_LENGTH = 10;

  let generations: GenerationModel[] = [];

  if (!process) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  process.generationPageStore.isPageCached(1)
    ? (generations = process.generationPageStore.getPageItems(1).slice(0, 3))
    : void processStore.fetchGenerationsForProcess(process, 1);

  return (
    <div>
      {processStore.isGenerationPageFetching(process.id, 1) && (
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      )}

      {!processStore.isGenerationPageFetching(process.id, 1) &&
        (generations.length < 1 ? (
          <div className="text-sm text-zinc-950">
            This process has not been generated yet
          </div>
        ) : (
          generations?.map((generation, index) => (
            <a
              key={index}
              className="hover:cursor-pointer"
              onClick={() =>
                navigate(`/${process.id}/generation/${generation.id}`)
              }
            >
              <GenerationContainer
                className="text-zinc-500"
                isFirst={index === 0}
                isLast={index === generations.length - 1}
              >
                <div className="flex-1">
                  <div className="font-medium text-zinc-950">
                    {generation.id}
                  </div>
                  <div className="flex flex-row items-center">
                    {ENV_TO_READABLE_NAME[ENV]}
                    {generation.getCurrentlyDeployed() && (
                      <Badge
                        className="ml-2 font-normal"
                        variant={'pastelBlue'}
                      >
                        Current
                      </Badge>
                    )}
                  </div>
                </div>
                <div className="basis-36 grow-0 shrink-0">
                  <div className="flex flex-row items-center">
                    {generationStatusToProgress(generation.status)}
                    <div className="ml-2.5 font-medium">
                      {generation.status}
                    </div>
                  </div>
                  <div className="pl-6">
                    {generation.duration} ({generation.elapsedTime})
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex flex-row items-center">
                    <GitCommitHorizontal size={18} />
                    <div className="ml-2 text-zinc-950">
                      {generation.sourceTag}
                    </div>
                  </div>
                </div>
                <div className="justify-self-end">
                  <div className="flex flex-row items-center">
                    {generation.elapsedTime} by{' '}
                    {generation.getCreatedBy().name.slice(0, MAX_NAME_LENGTH)}
                    <Avatar className="h-6 w-6 ml-2">
                      <AvatarImage
                        src={
                          generation.getCreatedBy().avatar ??
                          'https://www.w3schools.com/howto/img_avatar.png'
                        }
                      />
                    </Avatar>
                  </div>
                </div>
              </GenerationContainer>
            </a>
          ))
        ))}
    </div>
  );
};

export default observer(LastGenerations);
