import { JobStatus } from 'shared';

import theme from '@/styles/theme';

import { GlobalETLStatus } from './types';

export const getStatusColor = (status) => {
  switch (status) {
    case JobStatus.COMPLETED:
      return 'bg-green-100';
    case JobStatus.RECEIVED:
      return `bg-red-100`;
    case JobStatus.IN_PROGRESS:
      return `#FCDD4B`;
    default:
      return theme.color.grey[3];
  }
};

export const getIconColors = (iconStatus: GlobalETLStatus) => {
  let bgColor: string, color: string;
  switch (iconStatus) {
    case GlobalETLStatus.HEALTHY:
      bgColor = 'bg-green-100';
      color = '#8BD2C1';
      break;
    case GlobalETLStatus.UNHEALTHY:
      bgColor = 'bg-red-100';
      color = '#F58388';

      break;
    case GlobalETLStatus.NOT_RUNNING:
    default:
      bgColor = 'bg-gray-100';
      color = 'text-gray-500';

      break;
  }

  return { bgColor, color };
};

export const validateFiltersInput = (input: string): boolean => {
  const regex = /^[a-zA-Z,]*$/;
  return regex.test(input);
};

export const validateSnakeCase = (input: string): boolean => {
  const regex = /^[a-z0-9_]*$/;
  return regex.test(input);
};
const UNITS_TO_SECONDS = {
  SECOND: 1,
  MINUTE: 60,
  HOUR: 3600,
  DAY: 86400,
  MONTH: 2592000, // 30 days
  YEAR: 31536000 // 365 days
};

export const formatSecondsToHumanDuration = (duration: number): string => {
  if (duration < UNITS_TO_SECONDS.MINUTE) {
    return `${duration}s`;
  } else if (duration < UNITS_TO_SECONDS.HOUR) {
    return `${Math.floor(duration / UNITS_TO_SECONDS.MINUTE)}m`;
  } else if (duration < UNITS_TO_SECONDS.DAY) {
    return `${Math.floor(duration / UNITS_TO_SECONDS.HOUR)}h`;
  } else if (duration < UNITS_TO_SECONDS.MONTH) {
    return `${Math.floor(duration / UNITS_TO_SECONDS.DAY)}d`;
  } else if (duration < UNITS_TO_SECONDS.YEAR) {
    return `${Math.floor(duration / UNITS_TO_SECONDS.MONTH)}mo`;
  } else {
    return `${Math.floor(duration / UNITS_TO_SECONDS.YEAR)}y`;
  }
};

export const getDuration = (
  triggered_at?: string,
  ended_at?: string | null
) => {
  const triggeredAt = new Date(triggered_at ?? '');
  const endedAt = ended_at ? new Date(ended_at) : triggeredAt;

  const duration = endedAt.getTime() - triggeredAt.getTime();
  const durationInSeconds = Math.floor(duration / 1000);

  return formatSecondsToHumanDuration(durationInSeconds);
};

export const getElapsedTime = (triggered_at?: string) => {
  const now = new Date();
  const timeElapsed = now.getTime() - new Date(triggered_at ?? '').getTime();
  const timeElapsedInSeconds = Math.floor(timeElapsed / 1000);

  return formatSecondsToHumanDuration(timeElapsedInSeconds).concat(' ago');
};
