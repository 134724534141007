import { makeObservable, observable, runInAction } from 'mobx';
import {
  CreateEtlJobDTO,
  EtlJob,
  JobStatus,
  PauseJobDTO,
  Pod,
  PodSchema,
  UpdateEtlJobDTO,
  createEtlJobSchema,
  etlJobSchema,
  updateEtlJobSchema
} from 'shared';

import { AnalyticsModel } from '@models/analytics.model';
import { WorkflowModel } from '@models/workflow.model';

import { newError } from '@/services/errors/errors';
import { ENV } from '@/utils/constants';
import { parseWithZod } from '@/utils/parseZodSchema';

import BaseStore from './base/base.store';
import RootStore from './root.store';

export class AnalyticsStore extends BaseStore<AnalyticsModel> {
  fetchingAnalyticsPages: Map<string, Set<number>> = new Map();
  fetchingAnalytics: Set<string> = new Set();
  private paginatedPods: Map<string, Map<number, Pod[]>> = new Map();
  constructor(rootStore: RootStore) {
    super(rootStore, AnalyticsModel, 'analytics');
    this.store_ready = true;

    makeObservable(this, {
      fetchingAnalyticsPages: observable,
      fetchingAnalytics: observable
    });
  }
  /* --------------------------------- Helpers -------------------------------- */

  public async fetchAndParseAnalytics(
    etl_id: string,
    workflow_published_id: string
  ): Promise<EtlJob | undefined> {
    if (this.fetchingAnalytics.has(workflow_published_id)) return;

    this.fetchingAnalytics.add(workflow_published_id);
    const polledAnalyticsDTO = await this.httpWrapper
      .get<EtlJob>(`/etl/${etl_id}`)
      .catch((error) => {
        newError('ETL-N0i0W', error);
        return;
      });

    this.fetchingAnalytics.delete(workflow_published_id);

    if (!polledAnalyticsDTO) {
      newError('ETL-MWwSj', `Failed to poll analytics ${etl_id}`);
      return;
    }

    const parsedAnalytics = this.parseFetchedAnalytics(polledAnalyticsDTO);

    return parsedAnalytics;
  }

  public parseFetchedAnalytics(analytics: unknown): EtlJob | undefined {
    const parsedAnalyticsData = etlJobSchema.safeParse(analytics);

    if (!parsedAnalyticsData.success) {
      newError('ETL-oVUUz', parsedAnalyticsData.error);
      return;
    }
    return parsedAnalyticsData.data;
  }

  public loadEtlJob(analytics: EtlJob): AnalyticsModel {
    const newAnalytics = new AnalyticsModel(this, analytics);

    this.set(analytics.id, newAnalytics);

    return newAnalytics;
  }

  getJobById(id: Maybe<AnalyticsModel['id']>): Maybe<AnalyticsModel> | Error {
    if (!id) return;
    const job: Maybe<AnalyticsModel> = this.get(id);

    if (!job) {
      newError(
        'ETL-usKgP',
        `job with id ${id} not found`,
        ['local', 'staging'].includes(ENV),
        {
          errorType: 'warning'
        }
      );
      return;
    }

    return job;
  }

  public createEtlJob(job: CreateEtlJobDTO) {
    const parsedData = parseWithZod(createEtlJobSchema, job, 'ETL-Lm9nc');
    if (!parsedData) return;
    const newEtlJob = this.create();
    Object.assign(newEtlJob, {
      ...job
    });
    this.httpWrapper.post('/', parsedData).catch((error: Error) => {
      newError('ETL-MIkoD', error, true);
    });
    return newEtlJob;
  }

  public async updateEtlJob(id: AnalyticsModel['id'], job: UpdateEtlJobDTO) {
    const parsedData = parseWithZod(updateEtlJobSchema, job, 'ETL-AUxGX');
    if (!parsedData) return;
    const etlJob: Maybe<AnalyticsModel> =
      await this.httpWrapper.post<AnalyticsModel>(`/${id}`, parsedData);
    const etlStore = this.get(id);
    if (etlJob && etlStore) {
      runInAction(() => {
        Object.assign(etlStore, job);
      });
    }

    return etlJob;
  }

  public async pauseEtlJob(id: AnalyticsModel['id'], data: PauseJobDTO) {
    const etlJob: Maybe<AnalyticsModel> =
      await this.httpWrapper.post<AnalyticsModel>(`/${id}/pause`, data);
    const etlStore = this.get(id);
    const newStatus =
      data && data.pause === true ? JobStatus.RECEIVED : JobStatus.IN_PROGRESS;
    if (etlJob && etlStore) {
      runInAction(() => {
        etlStore.setStatus(newStatus);
      });
    }

    return etlJob;
  }

  public async triggerEtlJob(
    id: AnalyticsModel['id'],
    workflow: WorkflowModel
  ): Promise<Pod | undefined> {
    const pod = await this.httpWrapper.post<Pod>(`/${id}/trigger`, {});
    const parsePod = PodSchema.safeParse(pod);
    if (!parsePod.success) {
      newError('ETL-6Ka4t', parsePod.error);
      return;
    }
    workflow.analyticPageStore.setTotalNumberOfItemsInDB(
      workflow.analyticPageStore.getTotalNumberOfItemsInDB() + 1
    );
    const currentPodPage = this.getPodPageItems(id, 1);

    const newPodList = [parsePod.data, ...currentPodPage];
    this.setPodPage(id, 1, newPodList);
    return pod;
  }

  /* --------------------------------- Pagination -------------------------------- */

  public isAnalyticsPageFetching(workflow_id: string, page: number): boolean {
    return this.fetchingAnalyticsPages.get(workflow_id)?.has(page) ?? false;
  }
  public setAnalyticsPageFetching(
    workflow_id: string,
    page: number,
    isFetching: boolean
  ): void {
    if (!this.fetchingAnalyticsPages.has(workflow_id)) {
      this.fetchingAnalyticsPages.set(workflow_id, new Set());
    }
    const pages = this.fetchingAnalyticsPages.get(workflow_id);
    if (!pages) return;

    isFetching ? pages.add(page) : pages.delete(page);
  }

  setPodPage(analyticsId: string, page: number, pods: Pod[]): void {
    if (!this.paginatedPods.has(analyticsId)) {
      this.paginatedPods.set(analyticsId, new Map());
    }
    const pages = this.paginatedPods.get(analyticsId);
    pages?.set(page, pods);
  }

  getPodPageItems(analyticsId: string, page: number): Pod[] {
    const pages = this.paginatedPods.get(analyticsId);
    if (!pages) {
      return [];
    }
    return pages.get(page) || [];
  }
}
