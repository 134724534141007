import React from 'react';

import { Check, Pause, TriangleAlert } from 'lucide-react';
import { observer } from 'mobx-react';
import { JobStatus, PodStatus } from 'shared';

import { GlobalETLStatus } from '../types';
import { getIconColors } from '../utils';

interface ETLStatusIconProps {
  podStatus: PodStatus;
  jobStatus: JobStatus;
}

const getIconStatus = (
  podStatus: PodStatus,
  jobStatus: JobStatus
): GlobalETLStatus => {
  if (jobStatus === JobStatus.IN_PROGRESS) {
    if (podStatus === PodStatus.FAILURE) {
      return GlobalETLStatus.UNHEALTHY;
    } else return GlobalETLStatus.HEALTHY;
  } else if (
    jobStatus === JobStatus.RECEIVED ||
    jobStatus === JobStatus.COMPLETED
  ) {
    return GlobalETLStatus.NOT_RUNNING;
  }
  return GlobalETLStatus.NOT_RUNNING;
};
const ETLStatusIcon: React.FC<ETLStatusIconProps> = ({
  podStatus,
  jobStatus
}) => {
  const iconStatus = getIconStatus(podStatus, jobStatus);
  const { bgColor, color } = getIconColors(iconStatus);
  if (!bgColor || !color) return;
  return (
    <div className="flex items-center gap-2">
      <div
        className={`flex items-center justify-center p-2 rounded-full ${bgColor} border  `}
      >
        {iconStatus === GlobalETLStatus.HEALTHY && (
          <Check className={color} size={18} />
        )}
        {iconStatus === GlobalETLStatus.UNHEALTHY && (
          <TriangleAlert className={color} size={18} />
        )}
        {iconStatus === GlobalETLStatus.NOT_RUNNING && (
          <Pause className={color} size={18} />
        )}
      </div>
    </div>
  );
};

export default observer(ETLStatusIcon);
