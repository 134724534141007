import { useEffect, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Avatar, AvatarImage } from '@components/ui/avatar';
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from '@components/ui/pagination';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';

import useStores from '@hooks/useStore';
import { useWorkflow } from '@hooks/useWorkflow';

import { AnalyticsModel } from '@models/analytics.model';

import { ENV, ENV_TO_READABLE_NAME } from '@/utils/constants';
import { CircularProgress } from '@mui/joy';

import { MAX_NAME_LENGTH } from '../generation';
import { GenerationContainer } from '../generation/generation.style';
import { SettingsContent } from '../settings.style';
import { SettingBody } from '../style';
import AnalyticsMonitor from './AnalyticsMonitor';
import { podStatusToProgress } from './analyticsDetails/AnalyticsCard';
import { getDuration, getElapsedTime } from './utils';

const Analytics = () => {
  const { workflow } = useWorkflow();

  const { analyticsStore, workflowStore } = useStores();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const computeCurrentPage = () => {
    const currentPageParam = searchParams.get('page');
    if (!currentPageParam) {
      return 1;
    }
    const page = parseInt(currentPageParam);
    if (
      page < 1 ||
      page > (workflow?.analyticPageStore.getTotalNumberOfPages() ?? 0)
    ) {
      return 1;
    }
    return page;
  };

  const currentPage = computeCurrentPage();

  let analytics: AnalyticsModel[] = useMemo(() => [], []);
  let pods: AnalyticsModel['pods'] = useMemo(() => [], []);

  useEffect(() => {
    const intervals: NodeJS.Timeout[] = [];
    if (!workflow || !workflow.published_id) return;

    const analyticsToPoll = analyticsStore
      .toArray()
      .filter((analytics) => analytics.shouldBePolled);
    for (const analytics of analyticsToPoll) {
      void analyticsStore
        .fetchAndParseAnalytics(analytics.id, workflow.published_id)
        .then((parsedAnalytics) => {
          if (!parsedAnalytics) {
            return;
          }
        });
    }

    return () => {
      intervals.forEach(clearInterval);
    };
  }, [analytics, analyticsStore, pods, workflow]);

  if (!workflow) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }
  if (!workflow.analyticPageStore.isPageCached(currentPage)) {
    void workflowStore.fetchAnalyticsForWorkflow(workflow, currentPage);
  }
  analytics = workflow.analyticPageStore.getPageItems(currentPage);
  if (analytics && analytics[0]) {
    pods = analyticsStore.getPodPageItems(analytics[0].id, currentPage);
  }

  const numberOfPages = workflow.analyticPageStore.getTotalNumberOfPages() ?? 0;
  return (
    <SettingsContent>
      <SettingBody>
        <div>
          {analyticsStore.isAnalyticsPageFetching(workflow.id, currentPage) && (
            <LoaderBox>
              <CircularProgress />
            </LoaderBox>
          )}
          {/* TODO : Add create Analytics */}

          {!analyticsStore.isAnalyticsPageFetching(workflow.id, currentPage) &&
          analytics.length < 1 ? (
            <div>There is no active Analytics ETL on this workflow </div>
          ) : (
            <>
              {analytics?.map((analytic) => (
                <div key={analytic.id}>
                  <AnalyticsMonitor analytics={analytic} />
                </div>
              ))}

              <div className="mt-11">
                {pods?.map((pod, index) => (
                  <a
                    key={pod.id}
                    className="hover:cursor-pointer"
                    onClick={() => navigate(`${analytics[0].id}/${pod.id}`)}
                  >
                    <GenerationContainer
                      className="text-zinc-500"
                      isFirst={index === 0}
                      isLast={index === pods.length - 1}
                    >
                      <div className="flex-1">
                        <div className="font-medium text-zinc-950">
                          {pod.id}
                        </div>
                        <div className="flex flex-row items-center">
                          {ENV_TO_READABLE_NAME[ENV]}
                        </div>
                      </div>
                      <div className="basis-36 grow-0 shrink-0">
                        <div className="flex flex-row items-center">
                          {podStatusToProgress(pod.status)}
                          <div className="ml-2.5 font-medium">{pod.status}</div>
                        </div>
                        <div className="pl-6">
                          {getDuration(pod.triggered_at, pod.ended_at)}{' '}
                          {getElapsedTime(pod.triggered_at)}
                        </div>
                      </div>
                      <div className="justify-self-end">
                        <div className="flex flex-row items-center">
                          {getElapsedTime(pod.triggered_at)} by
                          {analytics[0]
                            .getCreatedBy()
                            ?.slice(0, MAX_NAME_LENGTH)}
                          <Avatar className="h-6 w-6 ml-2">
                            <AvatarImage
                              src={
                                'https://www.w3schools.com/howto/img_avatar.png'
                              }
                            />
                          </Avatar>
                        </div>
                      </div>
                    </GenerationContainer>
                  </a>
                ))}
              </div>
            </>
          )}
        </div>

        {workflow.analyticPageStore.getTotalNumberOfItemsInDB() > 0 && (
          <Pagination className="mt-3">
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => {
                    navigate(
                      currentPage <= 1
                        ? `?page=${currentPage}`
                        : `?page=${currentPage - 1}`
                    );
                  }}
                />
              </PaginationItem>

              {Array.from(
                { length: numberOfPages },
                (_, index) => index + 1
              ).map((pageNumber) => {
                const shouldShowPage =
                  pageNumber === 1 ||
                  pageNumber === numberOfPages ||
                  Math.abs(pageNumber - currentPage) <= 1;

                if (!shouldShowPage) {
                  if (pageNumber === 2 || pageNumber === numberOfPages - 1) {
                    return (
                      <PaginationItem key={`ellipsis-${pageNumber}`}>
                        <PaginationEllipsis />
                      </PaginationItem>
                    );
                  }
                  return null;
                }

                return (
                  <PaginationItem key={pageNumber}>
                    <PaginationLink
                      onClick={() =>
                        setSearchParams({ page: pageNumber.toString() })
                      }
                      isActive={pageNumber === currentPage}
                    >
                      {pageNumber}
                    </PaginationLink>
                  </PaginationItem>
                );
              })}

              <PaginationItem>
                <PaginationNext
                  onClick={() =>
                    navigate(
                      currentPage >= numberOfPages
                        ? `?page=${currentPage}`
                        : `?page=${currentPage + 1}`
                    )
                  }
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        )}
      </SettingBody>
    </SettingsContent>
  );
};

export default observer(Analytics);
