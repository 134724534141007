import { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@components/ui/accordion';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';
import { SettingsContent } from '@pages/Settings/settings.style';
import { SettingBody } from '@pages/Settings/style';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';
import { useWorkflow } from '@hooks/useWorkflow';

import { ParamsList } from '@/routes/routes.types';
import { CircularProgress } from '@mui/joy';
import { LogViewer } from '@patternfly/react-log-viewer';

import AnalyticsCard, { podStatusToProgress } from './AnalyticsCard';

export const POLL_INTERVAL = 5000;

const AnalyticsDetails = () => {
  const process = useProcess();
  const { workflow } = useWorkflow();
  const { analyticsStore } = useStores();
  const analyticsId = useParams<string>()[ParamsList.AnalyticsId];
  const podId = useParams<string>()[ParamsList.PodId];

  const analytics = analyticsStore.get(analyticsId);
  const pod = analytics?.pods?.find((pod) => pod.id === podId);

  const formatLogsForDisplay = (logs?: string | null) => {
    if (!logs || typeof logs != 'string') return [];

    try {
      const parsedLogs = JSON.parse(logs) as Record<string, unknown> | string;

      if (typeof parsedLogs !== 'object') {
        return parsedLogs;
      }

      if (parsedLogs.stack) {
        return parsedLogs.stack as string;
      }

      return JSON.stringify(parsedLogs, null, 2);
    } catch (_error) {
      return logs;
    }
  };

  useEffect(() => {
    if (!analytics || !analytics.shouldBePolled || !workflow?.published_id)
      return;

    const interval = setInterval((): void => {
      void analyticsStore
        .fetchAndParseAnalytics(analytics.id, workflow?.published_id)
        .then((parsedAnalytics) => {
          if (!parsedAnalytics) {
            return;
          }

          return clearInterval(interval);
        });
    }, POLL_INTERVAL);

    return () => clearInterval(interval);
  }, [analytics, analyticsStore]);

  if (!analyticsId) {
    return <div>Analytics ID not found</div>;
  }

  if (!analytics || !pod) {
    void analyticsStore
      .fetchAndParseAnalytics(analyticsId, workflow?.published_id)
      .then((parsedAnalytics) => {
        if (!parsedAnalytics) {
          return;
        }
        return analyticsStore.loadEtlJob(parsedAnalytics);
      });
  }

  if (!process) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  if (!analytics || !pod) {
    return <div>Analytics not found</div>;
  }

  return (
    <SettingsContent>
      <SettingBody>
        <AnalyticsCard analytics={analytics} pod={pod} />

        <div className="border-b border-solid border-zinc-200 -mx-144 mb-10"></div>

        <h1 className="mb-6 font-medium">Analytics details</h1>

        <Accordion type="single" collapsible>
          <AccordionItem
            value="item-1"
            className="rounded-md border border-solid border-zinc-300 bg-white shadow-md bg-clip-border text-sm font-normal"
          >
            <AccordionTrigger className="px-4">
              <div className="flex items-center">
                <div className="mr-2">Logs</div>
                {podStatusToProgress(pod.status)}
              </div>
            </AccordionTrigger>
            <AccordionContent className="bg-zinc-50 h-72 rounded-b-md border-t border-solid border-zinc-300 font-mono pl-1">
              <LogViewer
                data={formatLogsForDisplay(pod.logs)}
                height="100%"
                hasLineNumbers={true}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </SettingBody>
    </SettingsContent>
  );
};

export default observer(AnalyticsDetails);
